//@ts-ignore
import marked from '../instance';

export const tag = (title: string) => {
  return `<div class="card tag mint default">${title}</div>`;
};

export const card = (name: string, content: string, attrs: Record<string, string> = {}) => {
  const title = attrs['title'] || 'Title';
  const description = content || 'Description';
  const tags = (attrs['tags']?.split(',') || []).map(tag).join('');

  const withImage = !!attrs['img'] && attrs['img'] !== 'null';

  let imageStr = '';

  if (withImage) {
    imageStr = `<div class="image-container">
    <img src="${attrs['img']}" alt="image" class="card-image" />
</div>`;
  }

  return `<a href="${attrs['url']}" data-attrs='${JSON.stringify(attrs)}'>
    <div class="embedded-card">
        ${imageStr}
        <div>
            <div class="card-header">${tags}</div>
            <div class="card-title">${title}</div>
            <div class="card-description">${marked.parse(description)}</div>
        </div>
    </div>
</a>`;
};
