//@ts-ignore
import marked from '../instance';

export const link = (name: string, content: string, attrs: Record<string, string> = {}) => {
  const description = content || 'Description';
  const img = attrs['img'] || '/assets/img/icons/ai.svg';
  const open = attrs['img'] || '/assets/img/icons/arrow-circle.svg';

  return `<a href="${attrs['url']}">
  <div class="embedded-link">
    <div class="image-container">
      <img src="${img}" alt="image" class="card-image"/>
    </div>
    <div>
      <div class="card-description">
        ${marked.parse(description)}
      </div>
    </div>
    <div class="image-container arrow">
      <img src="${open}" alt="image" class="card-image"/>
    </div>
  </div>
</a>`;
};
