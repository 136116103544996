import { Marked } from 'marked';
import { markedOptionsConfig } from './config';
// import {componentTokenizer} from "../extensions/chat-bot-embedded-message";

const marked = new Marked();

marked.use(markedOptionsConfig);
//TODO: support recursive render?
// marked.use({extensions: [componentTokenizer]});

export default marked;
