import { Component, computed, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MenuService } from './core/services/menu.service';
import { filter } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { DialogService } from './core/services/dialog.service';
import { AccessibilityService } from './core/services/accessibility.service';
import { TestDividerComponent } from './core/components/block/test-divider/test-divider.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TestDividerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  private menuService = inject(MenuService);
  private dialogService = inject(DialogService);
  private accessibilityService = inject(AccessibilityService);
  authService = inject(AuthService);

  isInitialized = computed(() => this.authService.isInitialized());

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.menuService.closeMenu();
      this.dialogService.closeAllDialogs();
    });
    this.accessibilityService.initA11y();
    this.accessibilityService.enable();
  }
}
