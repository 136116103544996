import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { AuthService } from './core/services/auth.service';
import { TokenInterceptor } from './core/interseptors/token.interceptor';
import { componentTokenizer } from './core/marked/extensions/chat-bot-embedded-message';
import { markedOptionsConfig } from './core/marked/instance/config';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export function initializeApp(authService: AuthService) {
  return (): Promise<void> =>
    authService.initializeUserState().then(() => {
      authService.isInitialized.set(true);
    });
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    provideClientHydration(),
    provideAnimationsAsync(),
    importProvidersFrom(HttpClientModule),
    provideHttpClient(withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService],
      multi: true,
    },
    provideMarkdown({
      loader: HttpClient,
      markedExtensions: [
        {
          extensions: [componentTokenizer],
        },
      ],
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: markedOptionsConfig,
      },
    }),
    provideCharts(withDefaultRegisterables()),
  ],
};
