// @ts-nocheck
import { components } from '../components';
import { decode } from 'html-entities';

const renderContent = (name, content, attrs = {}) => {
  const component = components[name];
  if (component) {
    return component(name, content, attrs);
  }
  return content;
};

export const componentTokenizer = {
  name: 'component',
  level: 'block',
  start(src) {
    return src.match(/:::/)?.index;
  },
  tokenizer(src, tokens) {
    const rule = /^:::\s*(\w+)\s*\n((?::[^\n]+\n)*)\n([\s\S]+?)\n:::\s*\/\1\s*$/m;
    const match = rule.exec(src);

    if (match) {
      return {
        type: 'component',
        raw: match[0],
        name: match[1],
        attrs: match[2].trim(),
        content: match[3].trim(),
      };
    }
  },
  renderer(token) {
    const attrs = {};
    const attrRegex = /^:(\w+)=["']([^"']+)["']/gm;
    let match;
    while ((match = attrRegex.exec(token.attrs)) !== null) {
      attrs[match[1]] = decode(match[2]);
    }

    return renderContent(token.name, token.content, attrs);
  },
};
