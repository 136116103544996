import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { PageResolver } from './core/resolvers/page.resolver';
import { LanguageResolver } from './core/resolvers/language.resolver';
import { Error404Component } from './pages/error404/error404.component';

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    resolve: {
      page: PageResolver,
      language: LanguageResolver,
    },
    data: {
      slug: 'general',
      additions: [
        'links',
        'filters',
        'breadCrumbs',
        'offerModal',
        'errors',
        'become-consultant',
        'cabinet-general',
        'home-menu',
      ],
    },
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: Error404Component },
];
