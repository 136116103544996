//@ts-ignore
import marked from '../instance';
export const aiMessage = (name: string, content: string, attrs: Record<string, string> = {}) => {
  const description = content || 'Description';
  const img = '/assets/img/icons/ai.svg';

  return `<div class="ai-message">
        <div class="image-container">
            <img src="${img}" alt="image" class="card-image" />
        </div>
        <div>
            <div class="card-description">
               ${marked.parse(description)}
            </div>
        </div>
    </div>`;
};
