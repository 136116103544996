import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { EMPTY, mergeMap, of, take } from 'rxjs';
import { LangService } from '../services/lang.service';
import { LanguageModel } from '../models/language.model';

export const LanguageResolver: ResolveFn<LanguageModel[]> = () => {
  const langService = inject(LangService);
  return langService.list().pipe(
    take(1),
    mergeMap(langData => {
      if (langData) {
        return of(langData);
      } else return EMPTY;
    })
  );
};
